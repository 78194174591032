<template>
  <div class="vx-row">
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Faktu Number</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" v-model="invoice.faktur_number" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6">
          <span>Customer</span>
        </div>
        <div class="vx-col vx-row sm:w-5/6">
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input class="w-full" :value="invoice.client.code+' '+invoice.client.name" disabled />
          </div>
          <div class="vx-col sm:w-1/6 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseCustomer()">Browse</vs-button>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6">
          <span>Bill to</span>
        </div>
        <div class="vx-col vx-row sm:w-5/6">
          <div class="vx-col sm:w-5/6 w-full">
          <!-- <vs-input class="w-full" v-model="invoice.BillAddress.Address" disabled /> -->
            <vx-card class="w-full" no-shadow card-border style="margin-bottom:10px;">
              <div class="vx-row">
                <div class="vx-col w-full" v-if="Object.keys(invoice.bill_to).length > 0">
                  Address : {{ invoice.bill_to.address }}<br>
                  Subdistrict : {{ invoice.bill_to.sub_district }}<br>
                  District : {{ invoice.bill_to.district }}<br>
                  City : {{ invoice.bill_to.city }}<br>
                  Province : {{ invoice.bill_to.province }}<br>
                  Country : {{ invoice.bill_to.country }}<br>
                  Postal Code : {{ invoice.bill_to.postal_code }}
                </div>
              </div>

              <div class="block overflow-x-auto"></div>
            </vx-card>
          </div>
          <div class="vx-col sm:w-1/6 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseBillTo()">Browse</vs-button>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Note</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-textarea
            :disabled="!selected.isEdit"
            counter="255"
            label="Counter: 255"
            :counter-danger.sync="counterDanger"
            v-model="invoice.notes"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Date</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <flat-pickr
            :config="configFlatPickr"
            v-model="invoice.date"
            placeholder="Date"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Due Date</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <flat-pickr
            :config="configFlatPickr"
            v-model="invoice.due_date"
            placeholder="Due Date"
          />
        </div>
      </div>
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Sales Order</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-button size="small" class="mr-3 mb-2" @click="browseSalesOrder()">Browse</vs-button>
        </div>
      </div>
      <!-- Sales Order -->
      <template  v-for="(tr,index) in invoice.sales_order ">
      <vx-card v-bind:key="tr.Code+'-'+index" class="mt-base" no-shadow card-border style="margin-bottom:10px">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Sales Order [{{tr.Code}}]</span>
            </div>
            <vs-divider />
            <vs-table stripe bordered :data="tr.Line" style="width:100%">
              <template slot="thead">
                  <vs-th>Code</vs-th>
                  <vs-th>Name</vs-th>
                  <vs-th>Qty</vs-th>
                  <vs-th>Price</vs-th>
                  <vs-th>Sub Total</vs-th>
              </template>
              <template slot-scope="{data}">
                  <vs-tr :key="index" v-for="(dt, index) in data">
                      <vs-td>{{dt.ItemCode}}</vs-td>
                      <vs-td>{{dt.ItemName}}</vs-td>
                      <vs-td>{{dt.Quantity}}</vs-td>
                      <vs-td>{{dt.SellPrice}}</vs-td>
                      <vs-td>{{dt.Subtotal}}</vs-td>
                  </vs-tr>
              </template>
              
          </vs-table>
          </div>
        </div>

        <div class="block overflow-x-auto"></div>
      </vx-card>
      </template>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Total</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full" v-if="invoice.sales_order.length > 0">
          {{priceFormat(invoice.sales_order.reduce((acc, value) => acc + value.total, 0))}}
        </div>
      </div>
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-6/6 w-full">
          <vs-button :disabled="!selected.isEdit" class="mr-3 mb-2" @click="Submitinvoice()">Submit</vs-button>
        </div>
      </div>
    </div>
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <!-- start search item-->
      <template v-if="option.client">
        <div class="vx-row mb-6">
          <h3>Customer</h3>
        </div>
        <div class="vx-row mb-6">
          <data-table
            :responseData="responseData"
            :propsParams="params"
            :header="headerOption.client"
            @reloadDataFromChild="getOptionCustomer"
            style="padding-left:10px;width:100%"
          >
            <template slot="tbody">
              <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.data">
                <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
                <vs-td>{{tr.code}}</vs-td>
                <vs-td>{{tr.contact_name}}</vs-td>
                 <vs-td>
                    City : {{tr.city}} <br>
                    Address : {{tr.address}}
                </vs-td>
                <vs-td>{{tr.customer_category_name}}</vs-td>
                <vs-td>
                  <vx-tooltip v-if="selected.isEdit!=false" text="Add items">
                    <vs-button color="success" type="line" icon-pack="feather" icon="icon-check" @click="selectCustomer(tr)" />
                  </vx-tooltip>
                  <vx-tooltip v-else text="Cant Add Item">
                    <vs-button color="success" disabled  type="line" icon-pack="feather" icon="icon-check"/>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </data-table>
        </div>
      </template>
      <template v-if="option.bill_to">
        <div class="vx-row mb-6">
          <h3>Bill To</h3>
        </div>
        <div class="vx-row mb-6">
          <data-table
            :responseData="responseData"
            :propsParams="params"
            :header="headerOption.bill_to"
            @reloadDataFromChild="getOptionBillTo"
            style="padding-left:10px;width:100%"
          >
            <template slot="tbody">
              <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.data">
                <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
                <vs-td>
                    Address : {{ tr.address }}<br>
                    Subdistrict : {{ tr.sub_district }}<br>
                    District : {{ tr.district }}<br>
                    City : {{ tr.city }}<br>
                    Province : {{ tr.province }}<br>
                    Country : {{ tr.country }}<br>
                    Postal Code : {{ tr.postal_code }}
                </vs-td>
                <vs-td>
                    Address : {{ tr.map_address }}<br>
                    Lat : {{ tr.latitude }}<br>
                    Long : {{ tr.longitude }}
                </vs-td>                                
                <vs-td>
                    Name : {{ tr.contact_name }}<br>
                    Phone : {{ tr.phone }}<br>
                    Mobile : {{ tr.contact_mobile }}<br>
                    Email : {{ tr.email }}<br>
                    Fax : {{ tr.fax }}
                </vs-td>
                <vs-td>
                    <!-- <div v-for="item in tr.work_operation.work_operation_detail" :key="item.id" >
                        {{item.work_day.day}} ({{item.work_time.start_time}} - {{item.work_time.end_time}}) <br>
                    </div> -->
                </vs-td>
                <vs-td>
                  <vx-tooltip v-if="selected.isEdit!=false" text="Add items">
                    <vs-button color="success" type="line" icon-pack="feather" icon="icon-check" @click="selectBillTo(tr)" />
                  </vx-tooltip>
                  <vx-tooltip v-else text="Cant Add Item">
                    <vs-button color="success" disabled  type="line" icon-pack="feather" icon="icon-check"/>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </data-table>
        </div>
      </template>
      <template v-if="option.sales_order">
        <div class="vx-row mb-6">
          <h3>Sales Order</h3>
        </div>
        <div class="vx-row mb-6">
          <data-table
            :responseData="responseData"
            :propsParams="params"
            :header="headerOption.sales_order"
            @reloadDataFromChild="getOptionSalesOrder"
            style="padding-left:10px;width:100%"
          >
            <template slot="tbody">
              <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.data">
                <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
                <vs-td>{{tr.Code}}</vs-td>
                <!-- <vs-td>{{tr.contact_name}}</vs-td>
                 <vs-td>
                    City : {{tr.city}} <br>
                    Address : {{tr.address}}
                </vs-td>
                <vs-td>{{tr.customer_category_name}}</vs-td> -->
                <vs-td>
                  <vx-tooltip v-if="selected.isEdit!=false" text="Add items">
                    <vs-button color="success" type="line" icon-pack="feather" icon="icon-plus" @click="addSalesOrder(tr)" />
                  </vx-tooltip>
                  <vx-tooltip v-else text="Cant Add Item">
                    <vs-button color="success" disabled  type="line" icon-pack="feather" icon="icon-check"/>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </data-table>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import VueNumberInput from "@chenfengyuan/vue-number-input";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    VueNumberInput,
    flatPickr
  },
  props: {
    selected: Object
  },
  created() {
    //console.log("ini")
    if (Object.keys(this.selected.invoice).length > 0) {
      //console.log(this.selected.invoice)
      //console.log("ini ya")
      this.editOrShow();
    }
  },
  destroyed() {
    this.selected.isEdit = true;
    this.selected.invoice = {};
  },
  data: () => ({
    invoice: {
      id: 0,
      code: "",
      faktur_number : "",
      company:{
        code: "",
        name: "",
      },
      client:{
        id:"",
        code: "",
        name: "",
      },
      bill_to:{

      },
      notes : "",
      date : "",
      due_date : "",
      time : "",
      subtotal : 0,
      tax_rate : 0,
      tax_amount : 0,
      discount : 0,
      other_cost : 0,
      total : 0,
      status : 0,
      sales_order: [],
      delete_sales_order: []
    },
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "",
      sort: ""
    },
    header: [
      {
        text: "Code",
        // value: "sku_code"
        sortable: false
      },
      {
        text: "Name",
        // value: "item_name"
        sortable: false
      },
      {
        text: "Unit",
        // value: "name"
        sortable: false
      },
      {
        text: "Action",
        sortable: false
      }
    ],
    configFlatPickr: {
      // wrap: true, // set wrap to true only when using 'input-group'
       altFormat: 'F j, Y',
      // altInput: true,
      dateFormat: 'j F Y',
      
    },
    option:{
      client:true,
      bill_to:false,
      sales_order:false
    },
    headerOption:{
      client:[
            {text: "Code",value:'code',sortable: true},
            {text: "Name",value:'contact_name',sortable:true},
            {text: "Adress", sortable: false},
            {text: "Category", sortable: false},
            {text: "Action",sortable: false}
          ],
      bill_to:[
            {text: "Adress", sortable: false},
            {text: "Map",sortable: false},
            {text: "Contract",sortable: false},
            {text: "Work Operation", sortable: false},
            {text: "Action",sortable: false}
          ],
       sales_order:[
            {text: "Sales Order Number", value:'code',sortable: true},
            // {text: "", value:'code',sortable: true},
            // {text: "Map",sortable: false},
            // {text: "Contract",sortable: false},
            // {text: "Work Operation", sortable: false},
            {text: "Action",sortable: false}
          ],
    },
    sales_order:{
      line:[]
    },
    responseData: {},
    counterDanger: false,
    tabsIndex: 0,
    indexDelete: "",
    priceRule: false,
    isEditorShow: false,
    territory: [],
    unit: []
  }),
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    remove() {
      this.invoice.items = [];
      this.reloadData(this.params, this.priceRule);
    },
    browseCustomer() {
        this.params.search = "";
        this.params.sort = "";
        this.params.order = "";
        this.option.client = true;
        this.option.bill_to = false;
        this.option.sales_order = false;
        // this.optionItemAvailableShow = false;
        // this.optionBillToShow = false;
        // this.optionShipToShow = false;
        this.getOptionCustomer(this.params);
    },
    browseSalesOrder() {
        this.params.search = "";
        this.params.sort = "asc";
        this.params.order = "code";
        this.option.client = false;
        this.option.bill_to = false;
        this.option.sales_order = true;
        // this.optionItemAvailableShow = false;
        // this.optionBillToShow = false;
        // this.optionShipToShow = false;
        this.getOptionSalesOrder(this.params);
    },
    browseBillTo() {
        // console.log(optionCustomer,optionItemAvailable,optionBillTo,optionShipTo);
        // console.log("before", this.optionBillTo);
        this.params.search = "";
        this.params.sort = "";
        this.params.order = "";
        this.option.client = false;
        this.option.bill_to = true;
        this.option.sales_order = false;
        this.getOptionBillTo(this.params);
        // console.log("after", this.optionBillTo);
    },
    getOptionCustomer(params) {
            // this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer", {
                params: {
                    search: params.search,
                    limit: params.length,
                    length:params.length,
                    offset: params.length * (params.page - 1),
                    page:params.page,
                    order: params.sort,
                    sort: params.order,
                    type: "End Customer",
                    territory_id: this.territoryId,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                // console.log("option customer", resp);
                if(resp.code == 200) {
                    // this.data = resp.data.records;
                    this.responseData= {
                        data : resp.data.records,
                        recordsTotal:  resp.data.total_record,
                        length : resp.data.records.length
                    }
                    this.checkedAll = false;
                }else {
                  this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Customer",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                  });
                }
                this.$vs.loading.close();
            });
        },
        getOptionBillTo(params) {
            // this.isActive = page;
            if(this.invoice.client.id==""){
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Bill To Address",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
              });
              return true;
            }
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer-address/bill-to", {
                params: {
                    search: params.search,
                    limit: params.length,
                    length:params.length,
                    offset: params.length * (params.page - 1),
                    page:params.page,
                    order: params.sort,
                    sort: params.order,
                    customer_id: this.invoice.client.id,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if(resp.code == 200) {
                    // this.data = resp.data.records;
                    this.responseData= {
                        data : resp.data.records,
                        recordsTotal:  resp.data.total_record,
                        length : resp.data.records.length
                    }
                    this.checkedAll = false;
                }else {
                  this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Bill To Address",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                  });
                }
                this.$vs.loading.close();
            });
        },
        getOptionSalesOrder(params) {
            // this.isActive = page;
            if(this.invoice.client.id==""){
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Bill To Address",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
              });
              return false;
            }
            this.$vs.loading();
            this.$http.get("/api/v1/invoice/list/sales-order", {
                params: {
                    search: params.search,
                    limit: params.length,
                    length:params.length,
                    offset: params.length * (params.page - 1),
                    page:params.page,
                    order: params.sort,
                    sort: params.order,
                    customer_id: this.invoice.client.id,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if(resp.code == 200) {
                    // this.data = resp.data.records;
                    this.responseData= {
                        data : resp.data.salesOrders,
                        recordsTotal:  resp.data.recordsTotal,
                        length : resp.data.length
                    }
                    this.checkedAll = false;
                }else {
                  this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Bill To Address",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                  });
                }
                this.$vs.loading.close();
            });
        },
    test() {
      //console.log(this.invoice.items)
    },
    selectCustomer(customer){
      this.invoice.client = {
        id : customer.id,
        code : customer.code,
        name : customer.contact_name,
      }
      this.invoice.client_code = customer.code
      this.invoice.client_name = customer.contact_name
    },
    selectBillTo(bill_to){
      this.invoice.bill_to = bill_to
    },
    addSalesOrder(sales_order){
      if(!this.invoice.sales_order.includes(sales_order)){
        sales_order.SalesOrderID = sales_order.ID
        sales_order.ID = 0
        sales_order.total = sales_order.Line.reduce((acc, value) => acc + value.Total, 0)
        // console.log(sales_order)
        this.invoice.sales_order.push(sales_order)
      }
    },
    deleteSalesOrder(index, dt) {
      //console.log(this.invoice.items[index])
      this.indexDelete = index;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure remove " + dt.name + " from lines?",
        accept: this.confirmDelete
      });
    },
    confirmDelete() {
      this.$vs.loading();

      setTimeout(() => {
        this.$vs.loading.close();
        if (this.invoice.delete_sales_order[this.indexDelete].id) {
          this.invoice.delete_items.push(this.invoice.sales_order[this.indexDelete]);
        }
        this.invoice.sales_order.splice(this.indexDelete, 1);
      }, 1000);
    },
    Submitinvoice() {
      this.invoice.total = this.invoice.sales_order.reduce((acc, value) => acc + value.total, 0)
      if (this.isEditorShow) {
        this.$http
          .put("api/v1/invoice/store", JSON.stringify(this.invoice))
          .then(resp => {
            //console.log(resp);
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
              });
              this.editOrShow()
            } else {
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
              });
            }
          });
      } else {
        this.$http
          .post("api/v1/invoice/store", JSON.stringify(this.invoice))
          .then(resp => {
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
              });
              this.selected.invoice.id = resp.data.invoice.id;
              this.editOrShow()
            } else {
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check"
              });
            }
          });
      }

      //   setTimeout(function() {
      this.$vs.loading.close();
      // this.$emit("changeTab", 0, { name: "All" }, {}, false);
      //console.log("update yaa pindah")
      //   }, 1000);
    },
    browsePriceRule() {
      this.priceRule = true;
      this.reloadData(this.params, true);
    },
    browseItem() {
      this.priceRule = false;
      this.reloadData(this.params, false);
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      console.log(this.invoice.budget_value);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    editOrShow() {
      //console.log(this.selected.invoice)
      //console.log("this.selected.invoice")
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/read", {
          params: {
            invoice_id: this.selected.invoice.id
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            this.invoiceValue(resp.data);
            this.isEditorShow = true;
          } else {
            // //console.log(resp.code)
            this.$vs.dialog({
              type: "alert",
              color: "danger",
              title: `Alert`,
              text: "Whoops"
            });
          }
          this.$vs.loading.close();
        });
    },
    changeQuota() {
      this.invoice.budget_quota = this.invoice.territory.reduce(
        (acc, value) => acc + value.quota,
        0
      );
      console.log(this.invoice.budget_quota);
    },
    invoiceValue(invoice) {
      this.invoice.items = [];
      this.invoice.delete_items = [];
      this.invoice.id = invoice.invoice.id;
      this.invoice.code= invoice.invoice.Code
      this.invoice.faktur_number= invoice.invoice.FakturNumber,
      this.invoice.client ={
        id:invoice.customer.id,
        code: invoice.invoice.ClientCode,
        name: invoice.invoice.ClientName,
      }
      this.invoice.client_code = invoice.invoice.ClientCode
      this.invoice.client_name = invoice.invoice.ClientName
      this.invoice.notes = invoice.invoice.Note
      this.invoice.date = invoice.invoice.Date
      this.invoice.due_date = invoice.invoice.DueDate
      this.invoice.time = invoice.invoice.Time
      this.invoice.subtotal= invoice.invoice.Subtotal
      this.invoice.tax_rate= invoice.invoice.TaxRate
      this.invoice.tax_amount= invoice.invoice.TaxAmount
      this.invoice.discount= invoice.invoice.Discount
      this.invoice.other_cost= invoice.invoice.OtherCost
      this.invoice.total= invoice.invoice.Total
      this.invoice.status= invoice.invoice.Status
      this.invoice.sales_order = []
      for(var x in invoice.invoiceLine){
        invoice.invoiceLine[x].total = invoice.invoiceLine[x].Line.reduce((acc, value) => acc + value.Total, 0)
        this.invoice.sales_order.push(invoice.invoiceLine[x])
        for(var y in this.invoice.sales_order[x].Line){
          this.invoice.sales_order[x].Line[y].Quantity = this.invoice.sales_order[x].Line[y].Qty
          this.invoice.sales_order[x].Line[y].SellPrice = this.invoice.sales_order[x].Line[y].Price
          
        }
      }
    }
  }
};
</script>
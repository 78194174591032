<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Billing">
      <div class="flex flex-col gap-4">
        <operating-unit-filter
          @onShow="onShow"
          :hideShowButton="true"
          :multipleTerritory="true"
        />
        <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
          <customer-filter
            :multiple="true"
            :full="true"
            :showClearButton="true"
          />
        </div>

        <div
          v-if="selected.tab > 0"
          class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap"
        >
          <span>Posting Date</span>
          <div class="flex justify-between">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              :min-date="minDate"
              :max-date="maxDate"
              @start-selection="onDateStartSelect"
              @finish-selection="onDateEndSelect"
              v-model="postingDate"
              :linkedCalendars="true"
              :name="postingDate"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ globalDateFormat(picker.startDate) }} -
                {{ globalDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
            <vs-button
              class="ml-4"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="
                () => {
                  this.postingDate = { startDate: null, endDate: null };
                }
              "
            ></vs-button>
            <!-- <template>
            <vx-tooltip text="reset">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-refresh-cw"
                @click.stop="handleResetpostingDateFilter()"
              />
            </vx-tooltip>
          </template> -->
          </div>
        </div>
        <div
          v-if="selected.tab > 0"
          class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap"
        >
          <span>Type</span>
          <div class="flex justify-between">
            <multiselect
              class="selectExample"
              v-model="selectedType"
              :options="typeOptions"
              :multiple="false"
              :group-select="false"
              label="name"
              track-by="name"
              placeholder="Type to search"
            >
            </multiselect>
            <vs-button
              class="ml-4"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="onClearPatmentTerm"
            ></vs-button>
            <!-- <template>
            <vx-tooltip text="reset">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-refresh-cw"
                @click.stop="handleResetpostingDateFilter()"
              />
            </vx-tooltip>
          </template> -->
          </div>
        </div>

        <div class="flex gap-3 sm:w-2/3 w-full justify-end mt-5 mb-5">
          <vs-button @click="onShow">Show</vs-button>
        </div>

        <vs-tabs v-model="selected.tab">
          <vs-tab
            @click="colorx = 'danger'"
            label="Generate Invoice"
            v-if="
              this.$route.path == '/billing' ||
              this.$route.path == '/consignment-sales'
            "
          >
            <div class="con-tab-ejemplo">
              <proof-of-deliveries :isReload="isReload" />
            </div>
          </vs-tab>

          <vs-tab @click="colorx = 'danger'" label="Waiting Payment">
            <div class="con-tab-ejemplo">
              <waiting-payment-invoice
                :selected="selected"
                @changeTab="selectedTab"
                :isReload="isReload"
                :postingDate="postingDate"
                :paymentTerm="selectedType"
              ></waiting-payment-invoice>
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'danger'" label="Partial Received">
            <div class="con-tab-ejemplo">
              <partial-payment-invoice
                :selected="selected"
                @changeTab="selectedTab"
                :isReload="isReload"
                :postingDate="postingDate"
                :paymentTerm="selectedType"
              ></partial-payment-invoice>
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'danger'" label="Complete">
            <div class="con-tab-ejemplo">
              <closed
                :selected="selected"
                @changeTab="selectedTab"
                :isReload="isReload"
                :postingDate="postingDate"
                :paymentTerm="selectedType"
              ></closed>
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'danger'" label="List of Invoices">
            <div class="con-tab-ejemplo">
              <invoices
                :selected="selected"
                @changeTab="selectedTab"
                :isReload="isReload"
                :postingDate="postingDate"
                :paymentTerm="selectedType"
              ></invoices>
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'danger'" label="Waiting Approval">
            <div class="con-tab-ejemplo">
              <waiting-approval
                :selected="selected"
                @changeTab="selectedTab"
                :isReload="isReload"
                :postingDate="postingDate"
                :paymentTerm="selectedType"
              ></waiting-approval>
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'danger'" label="Reversed">
            <div class="con-tab-ejemplo">
              <reversed
                :selected="selected"
                @changeTab="selectedTab"
                :isReload="isReload"
                :postingDate="postingDate"
                :paymentTerm="selectedType"
              ></reversed>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import moment from "moment";
import form from "./form/form.vue";
import list from "./list/list.vue";
import closed from "./closed/closed.vue";
import reversed from "./reversed/reversed.vue";
import validation from "./validation/validation.vue";
import waitingPayment from "./waiting-payment/waiting-payment.vue";
import partialPayment from "./partial-payment/partial-payment.vue";
import approval from "./approval/approval.vue";
import release from "./release/release.vue";
import complete from "./complete/complete.vue";
import SalesOrderComplete from "./sales_order_complete/sales_order_complete.vue";
import operatingUnitFilter from "../../../components/operatingUnitFilter/operatingUnitFilter.vue";
import proofOfDeleveries from "./pod/proof-of-deliveries.vue";
import waitingApproval from "./waiting-approval/waiting-approval.vue";
import DateRangePicker from "vue2-daterange-picker";
import Invoices from "./invoices/index.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import customerFilterVue from "../../../components/customerFilter/customerFilter.vue";
export default {
  components: {
    "form-invoice": form,
    "list-invoice": list,
    "validation-invoice": validation,
    "approval-invoice": approval,
    "release-invoice": release,
    "complete-invoice": complete,
    "waiting-payment-invoice": waitingPayment,
    closed: closed,
    "partial-payment-invoice": partialPayment,
    "sales-order-complete": SalesOrderComplete,
    reversed: reversed,
    "operating-unit-filter": operatingUnitFilter,
    "proof-of-deliveries": proofOfDeleveries,
    "waiting-approval": waitingApproval,
    "date-range-picker": DateRangePicker,
    invoices: Invoices,
    "customer-filter": customerFilterVue,
  },
  data: () => ({
    selected: {
      territory: {},
      tab: 0,
      itemKit: {},
      isEdit: true,
    },
    isReload: false,
    postingDate: {
      startDate: null,
      endDate: null,
    },
    typeOptions: [{ name: "All" }, { name: "COD" }, { name: "Non COD" }],
    selectedType: "",
    minDate: null,
    maxDate: null,
  }),
  watch: {},
  methods: {
    selectedTab(value, invoice, isEdit = false) {
      //  this.selected.territory  = option
      this.selected.invoice = invoice;
      console.log(this.selected.invoice);
      this.selected.tab = value;
      this.selected.isEdit = isEdit;
    },
    onShow() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
    },
    onDateStartSelect(date) {
      const startDate = moment(date);
      this.maxDate = startDate.add(7, "days").format("YYYY-MM-DD HH:mm:ss");
      this.minDate = startDate
        .subtract(14, "days")
        .format("YYYY-MM-DD HH:mm:ss");

      console.log(this.maxDate);
      console.log(this.minDate);
    },
    onDateEndSelect() {
      this.maxDate = null;
      this.minDate = null;
    },
    onClearPatmentTerm() {
      this.selectedType = { name: "All" };
    },
  },
  mounted() {
    // this.showOption()
    this.selectedType = { name: "All" };
  },
};
</script>
<style>
.multiselect__tags {
  min-height: 32px;
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect__content-wrapper {
  z-index: 100;
  background: #fff;
}
</style>

<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.invoices" v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '']">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-td>
                  <template>
                    <vx-tooltip text="Show promotion">
                        <feather-icon  icon="SearchIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="ml-4" @click="clickShow(tr)"/>
                    </vx-tooltip>
                    <vx-tooltip text="Edit promotion">
                        <feather-icon  icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-4" @click="clickEdit(tr)"/>
                    </vx-tooltip>
                    <vx-tooltip text="Delete promotion">
                        <feather-icon  icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-4" @click="clickDelete(tr)"/>
                    </vx-tooltip>
                  </template>
              </vs-td>
              <vs-td>
                Code : {{tr.Code}}<br>
                Faktur : {{tr.FakturNumber}}<br>
                Due Date : {{tr.DueDate}}<br>
                Note : {{tr.Note}}<br>
              </vs-td>
              <vs-td>
                Code : {{tr.ClientCode}}<br>
                Name : {{tr.ClientName}}<br>
                
              </vs-td>
              <vs-td>
                Tax : {{tr.TaxAmount}}<br>
                Tax Rate : {{tr.TaxRate}}<br>
                Total : {{priceFormat(tr.Total)}}<br>
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
    components: {
    },
    props:{
        selected :  Object,
        option : Object
    },
    data() {
        return {
        params: {
            search: "",
            length: 10,
            page: 1,
            order: "asc",
            sort: "code"
        },
        header: [
            {
                text: "Action",sortable: false,width: '5%'
            },
            {
                text: "Invoice",value: "code"
                // width: '5%'
            },
            {
                text: "Client",sortable: false
            },
            {
                text: "Amount",sortable: false
            }
        ],
        responseData: {},
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        detail: false,
        selectedData: {},
        };
    },
    computed:{
        territory:{
            get: function () {
                return "("+this.selected.territory.code+") "+this.selected.territory.name
            }
        }
    },
    watch:{
        territory:function(){
            // this.kitting.territory = this.selected.territory
            this.reloadData(this.params)
        }
    },
    mounted() {
        this.reloadData(this.params);
        
        // this.getData();
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        clickShow(tr) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            // this.$http.get("api/v1/invoice/read",{
            //     params: {
            //         invoice_id:tr.id
            //     }
            // })
            // var option = this.option.territory.filter(function(territory)  {
            //       return territory.id == tr.TerritoryID;
            //     })[0]
            this.$emit("changeTab",1,tr,false)
        },
        clickEdit(tr) {
            console.log(tr)
            this.closeDetail();
            setTimeout(function() {}, 3000);
            // var option = this.option.territory.filter(function(territory)  {
            //       return territory.id == tr.TerritoryID;
            //     })[0]
            this.$emit("changeTab",1,tr,true)
        },
        clickDelete(tr){
          var  promotion_ids = []
          promotion_ids.push(tr.promotion.ID)
          console.log(promotion_ids)
          console.log(tr)
            this.$http
              .delete("api/v1/promotion/delete", {
                data :{
                  promotion_ids: promotion_ids
                }
              })
              .then(resp => {
              if (resp.code == 200) {
                  this.$vs.notify({
                  title: "Success",
                  text: "",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check"
                  });
                  this.reloadData(this.params)
              } else {
                  this.$vs.notify({
                  title: "Danger",
                  text: resp.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check"
                  });
              }
              })
              this.$vs.loading.close();
              
        },
        closeDetail() {
            this.detail = false;
            this.selectedData = {
                ID : 0
            };
        },
        
        reloadData(params) {
            this.params = params;
            console.log(params)
            this.$vs.loading();
            this.$http
                .get("api/v1/invoice/data-table", {
                params: {
                    search: params.search,
                    length: params.length,
                    page: params.page,
                    order: params.order,
                    sort: params.sort,
                    territory_id: this.selected.territory.id,
                    status: 0,
                }
                })
                .then(
                resp => {
                    if (resp.code == 500) {
                    this.$vs.loading.close();
                    } else if(resp.code == 200) {
                    this.$vs.loading.close();
                    this.data = resp.data.invoices;
                    this.responseData = resp.data;
                    this.checkedAll = false;
                    }
                    else {
                    this.$vs.loading.close();
                    }
                }
                );
                // this.$http
                // .get("/api/v1/master/price-rule", {
                //   params: {
                //     search: params.search,
                //     length: params.length,
                //     page: params.page,
                //     order: "",
                //     sort: "",
                //   }
                // })
        }
    }
    };
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
    background:antiquewhite;
    background-color: antiquewhite;
}
</style>
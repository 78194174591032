<template>
  <div class="flex flex-col gap-4">
    <vs-input
      v-model="podData.shipment_number"
      label="Shipment Number"
      class="w-full"
      readonly
    />
    <vs-input v-model="podDate" label="POD Date" class="w-full" readonly />
    <vs-input v-model="podStatus" label="Status" class="w-full" readonly />
    <vs-input
      v-model="podData.customer_code"
      label="Customer Code"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.customer_name"
      label="Customer Name"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.customer_address"
      label="Customer Address"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.do_code"
      label="Delivery Order Code"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.so_code"
      label="Sales Order Code"
      class="w-full"
      readonly
    />

    <h3 class="mt-4">Detail</h3>
    <vs-table :data="podData.info.line_convertion">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty</vs-th>
        <vs-th>Unit</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.ItemName">
            ({{ tr.ItemCode }}) {{ tr.ItemName }}
          </vs-td>

          <vs-td :data="tr.AdjustedQuantity">
            {{ tr.AdjustedQuantity }}
          </vs-td>

          <vs-td :data="tr.UnitName">
            {{ tr.UnitName }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    podData: Object,
  },
  data() {
    return {
      podStatus: "",
      podDate: "",
      detailData: [],
      //   itemList
    };
  },
  mounted() {
    console.log("podData", this.podData);
    this.podStatus = this.generatePODStatus(this.podData.status_delivery);
    this.podDate = this.podData.date
      ? moment(this.podData.date).format("DD-MM-YYYY")
      : "";

    this.getDetail(this.podData.do_id, this.podData.do_code);
  },
  methods: {
    generatePODStatus(statusNumber) {
      switch (statusNumber) {
        case 0:
          return "Open";
        case 1:
          return "Complete";
        case 2:
          return "Partial Complete";
        case 3:
          return "Partial Redeliver";
        case 4:
          return "Failed Complete";
        case 5:
          return "Failed Redeliver";
      }
    },
    getDetail(delivery_order_id, delivery_order_code) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/proof-of-delivery/detail", {
          params: {
            delivery_order_id,
            delivery_order_code,
          },
        })
        .then((r) => {
          console.log(r);
          if (r.code == 500) {
            this.$vs.loading.close();
          } else if (r.code == 200) {
            this.$vs.loading.close();
            // this.data = r.data.payment;
            this.detailData = r.data;
            console.log("r.data", r.data);
            // this.responseData.length = r.data.payment.length;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    podData() {
      console.log("watching data: "+ this.podDate);
      this.podStatus = this.generatePODStatus(this.podData.status_delivery);
      this.podDate = this.podData.date
        ? moment(this.podData.date).format("DD-MM-YYYY")
        : "";

      this.getDetail(this.podData.do_id, this.podData.do_code);
    }
  }
};
</script>
